import { BookerCalendarEvent, HourPerDayRecord, RoomAvailability } from '@chilipiper/api-type-def'
import { add, areIntervalsOverlapping, format, parse } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import uniq from 'lodash/uniq'
import {
  BookerCalendarParam,
  OriginalEventsCalendarParam,
} from '@chilipiper/api-type-def/src/payload/booker'
import { State as BookingState } from '../context/booking'
import { State as GlobalState } from '../context/global'
import { CalendarEvent, CustomRoomIds } from '../types'
import { convertTimezoneToLocal } from './timezone'
import { isSameOrAfter, isSameOrBefore } from './time'

export const getPayloadStep = (step: number) => {
  if (step === 5 || step === 10) {
    return 5
  }

  return 15
}

export function getCalendarPayloadFromState(bookingState: BookingState, globalState: GlobalState) {
  const { isBookingOnSelf, guests, assignee, showAvailabilityForAll, selectedQueue, prospect } =
    bookingState
  const mandatoryGuests = guests.filter(x => x.mandatory)
  const assigneesId = assignee?.id
    ? [assignee.id]
    : showAvailabilityForAll && selectedQueue
    ? selectedQueue?.members.map(m => m.id)
    : []

  const additionalGuests = []

  if (prospect) {
    additionalGuests.push(prospect.email)
  }

  if (
    selectedQueue?.withBookerGuest &&
    globalState.session.email &&
    globalState.session.email !== bookingState.assignee?.email
  ) {
    additionalGuests.push(globalState.session.email)
  }

  const payload = {
    assigneesIds: assigneesId,
    guests: uniq([...mandatoryGuests.map(g => g.email)].concat(additionalGuests)),
    start: bookingState.start.valueOf(),
    end: bookingState.end.valueOf(),
    withAvailableDays: showAvailabilityForAll,
    withBuffers: assigneesId.length > 1,
    template: bookingState.selectedTemplate?.id as string,
    step: getPayloadStep(bookingState.step),
    queueId: isBookingOnSelf ? undefined : bookingState.selectedQueue?.id,
    eventId: isBookingOnSelf ? undefined : bookingState.selectedEvent?.id,
  }

  return payload as BookerCalendarParam
}

export const slotsPayloadToCalendarPayload = (
  payload: BookerCalendarParam,
  workspaceId: string
): OriginalEventsCalendarParam => {
  return {
    assigneesIds: payload.assigneesIds,
    end: payload.end,
    guests: payload.guests,
    start: payload.start,
    workspaceId: workspaceId,
  }
}

export const isSlotInsideWorkingHours = (
  slot: BookerCalendarEvent,
  workingHours: HourPerDayRecord,
  step: number,
  timezone: string
) => {
  const slotDay = format(slot.start, 'EEEE').toLowerCase()
  const end = add(slot.start, { minutes: step })
  const dayHours = workingHours[slotDay].workingIntervals.map(interval => {
    return {
      start: zonedTimeToUtc(
        parse(
          `${interval.workStarts.hours}:${interval.workStarts.minutes}`,
          'H:m',
          new Date(slot.start)
        ),
        timezone
      ),
      end: zonedTimeToUtc(
        parse(
          `${interval.workEnds.hours}:${interval.workEnds.minutes}`,
          'H:m',
          new Date(slot.start)
        ),
        timezone
      ),
    }
  })
  return dayHours.some(interval => {
    return isSameOrAfter(new Date(slot.start), interval.start) && isSameOrBefore(end, interval.end)
  })
}

export const tranformEventsToCalendarEvents = (
  events: BookerCalendarEvent[],
  timezone: string
): CalendarEvent[] => {
  return events.reduce((acc: CalendarEvent[], event) => {
    const id = new Date().valueOf().toString()
    const start = convertTimezoneToLocal(event.start, timezone)
    const end = convertTimezoneToLocal(event.end, timezone)

    // prevent adding events that have their end date before their start date because of daylight savings changes
    if (start > end) {
      return acc
    }

    const hasEventOverlapping = events.find(
      e =>
        areIntervalsOverlapping(
          {
            start: e.start,
            end: e.end,
          },
          {
            start: event.start,
            end: event.end,
          }
        ) &&
        e.title !== event.title &&
        !e.isFullDay
    )

    const newEvent = {
      ...event,
      busyAssigneeIds: Array.from(
        new Set(event.assigneeIds.concat(event.cappedByTemplateAssigneeIds))
      ),
      id,
      start,
      end,
      title: event.title || '',
      hasEventOnSameStart: hasEventOverlapping !== undefined && !event.isFullDay,
    }

    if (event.isFullDay && event.start === event.end) {
      newEvent.end = add(event.start, { days: 1 })
    }

    acc.push(newEvent)

    return acc
  }, [])
}

export const transformEventsToCalendarSlots = (
  events: BookerCalendarEvent[],
  timezone: string,
  step: number,
  workingHourTimezone?: string,
  workingHours?: HourPerDayRecord
): CalendarEvent[] => {
  return events.reduce((acc: CalendarEvent[], event) => {
    const id = new Date().valueOf().toString()
    const start = convertTimezoneToLocal(event.start, timezone)
    const end = convertTimezoneToLocal(event.end, timezone)

    // prevent adding events that have their end date before their start date because of daylight savings changes
    if (start > end) {
      return acc
    }

    /*
      Currently the only duration that would allow booking outside working hours is 45 min, because it uses 15 min step in the calendar.
      Example: If the working hours ends at 6pm, a selection on 5:45 until 6:30 would be valid
    */
    const shouldCheckSlotIsOutsideWorkingHours = step === 45 && workingHours && workingHourTimezone
    acc.push({
      ...event,
      id,
      start,
      end,
      title: event.title || '',
      reserved: true,
      busyAssigneeIds: Array.from(
        new Set(event.assigneeIds.concat(event.cappedByTemplateAssigneeIds))
      ),
      isOutsideWorkingHours: shouldCheckSlotIsOutsideWorkingHours
        ? !isSlotInsideWorkingHours(event, workingHours, step, workingHourTimezone)
        : false,
    })
    return acc
  }, [])
}

export const mapEventsBusySlots = (
  slots: CalendarEvent[],
  events: CalendarEvent[],
  rooms: RoomAvailability[],
  bookingState: BookingState
) => {
  const roomId = bookingState.selectedRoom?.id as CustomRoomIds

  if (roomId !== 'ALL_ROOMS') {
    return slots
  }

  /*
    Check each slot to know if all rooms are booked on it. If true, we add "isBlockedForAnyRoom", which adds the busy class in the UI.
  */

  return slots.map(slot => {
    const eventsAtSlot = events.filter(event => event.start >= slot.start && event.end <= slot.end)
    const roomIds = uniq(eventsAtSlot.map(event => event.roomsIds[0]))
    if (eventsAtSlot.length > 0 && roomIds.length === rooms.length) {
      return {
        ...slot,
        reserved: false,
        isBlockedForAnyRoom: true,
        isBusy: true,
      }
    } else {
      return slot
    }
  })
}
